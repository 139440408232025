<template>
  <div class="w-100 p-0 overflow-hidden">
    <b-table
      class="position-relative"
      :items="members"
      responsive
      :small="isSmall"
      :fields="fields"
      :busy="busy"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >
      <!-- Column: Name -->
      <template #cell(name)="{ item }">
        <b-media class="d-flex align-items-center pl-1 ml-25 mt-25 ">
          <!-- <b-link :to="item.name ? getMemberLocation(item) : null" class="text-dark d-flex align-items-center"> -->
          <p class="font-weight-extrabold mb-0 text-capitalize truncated-text mt-75">
            {{ item.name }}
          </p>
          <!-- </b-link> -->
        </b-media>
      </template>
      <!-- Column: Type -->
      <template #cell(type)="{ item }">
        <p v-if="item.type" class="mb-0 pl-25 ml-1 text-capitalize truncated-text mt-75">
          {{ item.type }}
        </p>
        <p v-else class="text-muted mb-0 pl-25 ml-1 mt-75">---</p>
      </template>
      <!-- Column: Subtype -->
      <template #cell(subtype)="{ item }">
        <p v-if="item.subtype" class="mb-0 pl-25 ml-1 text-capitalize truncated-text mt-75">
          {{ item.subtype }}
        </p>
        <p v-else class="text-muted mb-0 pl-25 ml-1 mt-75">---</p>
      </template>

      <!-- Column: Created at -->
      <template #cell(date)="{ item }">
        <p v-if="item.createdAt" class="mb-0 pl-25 ml-1 text-capitalize truncated-text mt-75">
          {{ getDate(item.createdAt) }}
        </p>
        <span v-else class="text-muted mb-0 pl-25 ml-1 mt-75">---</span>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="{ item }">
        <feather-icon icon="Trash2Icon" class="pointer text-primary ml-3 mt-1" @click="removeItem(item)"/>
      </template>
      <template #table-busy>
        <div class="text-center p-5">
          <b-spinner
            size="2rem"
            type="grow"
            class="d-block m-auto m-1"
            variant="primary"
            label="Loading..."
          />
        </div>
      </template>
      <template #empty>
        <div class="horizontal-placeholder d-block">
          <div>
            <img :src="membersPlaceholder" class="d-block">
          </div>
          <div>
            <p class="text-primary text-center">
              {{ $t('available.message', { itemName: $t('backoffice.templates.title') }) }}
            </p>
          </div>
        </div>
      </template>
    </b-table>
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="template"
      @remove-item="handleRemoveItem"
    />
  </div>
</template>

<script>
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';

export default {
  name: 'MembersTable',
  components: { UnshareModal },
  mixins: [ToastNotificationsMixin],
  props: {
    members: {
      type: [Array, Function],
      default: () => [],
    },
    busy: Boolean,
  },
  data() {
    return {
      isSmall: false,
      isUnshareModalVisible: false,
      templates: [],
      currentTemplate: null,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('backoffice.templates.name'),
        },
        {
          key: 'type',
          label: this.$t('backoffice.templates.type'),
          sortable: false,
        },
        {
          key: 'subtype',
          label: this.$t('backoffice.templates.subtype'),
          sortable: false,
        },
        {
          key: 'date',
          label: this.$t('backoffice.templates.creation-date'),
          sortable: false,
        },
        {
          key: 'actions',
          label: this.$t('backoffice.members.actions'),
          sortable: false,
        },
      ];
    },
    membersPlaceholder() {
      return Placeholder;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getInitialData() {
      this.$store.dispatch('getItems', {
        itemType: 'layout/templates',
        customName: 'backofficeTemplates',
        forceAPICall: true,
      });
    },
    getDate(item) {
      const d = new Date(item);
      return d.toLocaleString('en-GB');
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    removeItem(item) {
      this.isUnshareModalVisible = true;
      this.currentTemplate = item;
    },
    async handleRemoveItem(response) {
      if (response === true) {
        try {
          await this.$store.dispatch('postItem', {
            type: 'layout/templates/delete',
            customName: 'backofficeTemplates',
            requestConfig: {
              templateKey: this.currentTemplate.key,
            },
            // noSet: true,
          });

          this.notifySuccess(this.$t('backoffice.templates.messages.remove-success'));
          await this.getInitialData();
        } catch {
          this.notifyError(this.$t('backofficetemplates.messages.remove-error'));
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.truncated-text {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  min-width: 0;
}
.check-button {
  cursor: pointer !important;
}
</style>
