<template>
  <backoffice-container>
    <b-card>
      <div class="d-flex justify-content-between flex-wrap my-1">
        <h4 class="mb-1">
          {{ $t('backoffice.templates.title') }}
        </h4>
        <!-- <b-button variant="primary" class="font-weight-bold" @click="isModalOpened = true">
          {{ $t('organizations.qr.button-text') }}
        </b-button> -->
      </div>
      <div v-if="members.length > 0">
        <base-pagination-container
          class="members-list-container"
          :total="members.length"
          :loading="isLoading"
          :items="members.length > 0 ? members : membersData"
          :per-page="perPage"
          :load-first-page="true"
          :search-placeholder="$t('backoffice.referrals.search')"
          :searchable="false"
        >
          <template #default="{ items, loading }">
            <app-scroll>
              <templates-table :members="items" :busy="loading" />
            </app-scroll>
          </template>
        </base-pagination-container>
      </div>
      <div v-else class="horizontal-placeholder d-flex flex-column flex-wrap justify-content-center align-items-center">
        <img :src="placeholder">
        <p class="text-primary">
          {{ $t('backoffice.feedback.placeholder-text', { item: $t('backoffice.templates.title') }) }}
        </p>
      </div>
    </b-card>
    <b-modal
      id="invite-modal"
      v-model="isModalOpened"
      :title="$t('organizations.qr.button-text')"
      size="sm"
      hide-footer
      @hidden="isCopied = false"
    >
      <h4 class="text-center my-1">
        {{ $t('organizations.qr.share-title') }}
      </h4>
      <img :src="link" style="width: 250px" class="d-block mx-auto small-card">
      <div class="text-center mt-2 mb-1">
        <b-button class="font-weight-bold" :variant="'primary'" @click="handleClipboardCopied()">
          <span
            class="d-flex pb-25"
          ><feather-icon icon="CopyIcon" size="18" class="d-block mx-auto mr-1" /><span class="pl-50 pt-25">{{
            $t('share.clipboard-title')
          }}</span></span>
        </b-button>
      </div>
      <b-alert :show="isCopied" variant="primary" class="text-center">
        {{ $t('share.copied-message') }}
      </b-alert>
    </b-modal>
  </backoffice-container>
</template>

<script>
import BasePaginationContainer from '@core/components/containers/base/BasePaginationContainer.vue';
import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
import AppScroll from '@/@core/components/app-scroll/AppScroll.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import TemplatesTable from '@/backoffice/components/TemplatesTable.vue';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import { getNewAuthRoute } from '@/auth/utils';

export default {
  name: 'Templates',
  components: {
    BasePaginationContainer,
    AppScroll,
    TemplatesTable,
    BackofficeContainer,
  },
  mixins: [CardWidgetMixin, ToastNotificationsMixin],
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      invited: {
        email: '',
      },
      isLoading: false,
      total: 0,
      filtersIndex: 0,
      filters: [],
      parentClassifiers: [],
      timeoutID: null,
      areFiltersVisible: false,
      allClassifierFilters: [],
      nextFilter: [],
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      selectClassifierParent: [],
      selectedCustomFieldsForFilter: [],
      index: 0,
      actualData: [],
      customFieldsInputs: [],
      customFieldToSend: {},
      isLoadingClassifiers: true,
      classifiers: [],
      page: 1,
      // members: [],
      isModalOpened: false,
      isCopied: false,
    };
  },
  computed: {
    members() {
      return this.$store.getters.backofficeTemplates.unpaginated;
    },
    placeholder() {
      return Placeholder;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
    qrUrl() {
      return getNewAuthRoute({
        slug: this.$route.params.communityId || "",
        subdomain: window.location.host.split(".")[0] !== "app" ? `${window.location.host.split(".")[0]}.` : null,
      }, `/signup?referral_type=community&referral_id=${this.community.key}`);
    },
    link() {
      return `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${this.qrUrl}'&choe=UTF-8'`;
    },
  },
  async created() {
    await this.getInitialData();
    // if (this.$store.getters.classifiers.members) {
    //   this.classifiers = this.$store.getters.classifiers.members.unpaginated;
    // }
  },
  methods: {
    getInitialData() {
      try {
        this.$store.dispatch('getItems', {
          itemType: 'layout/templates',
          customName: 'backofficeTemplates',
          forceAPICall: true,
          // requestConfig: {
          //   communitySlug: this.$route.params.communityId,
          // },
        });
        // this.members = this.membersData;
      } catch {
        this.notifyError(this.$t('error-message.load-failed'));
      }
    },
    // async handleLoadPage({ search }) {
    //   this.isLoading = true;
    //   this.members = search != '' ? this.members.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()) || item.surname.toLowerCase().includes(search.toLowerCase())) : this.membersData;
    //   this.isLoading = false;
    // },
    // handleClipboardCopied() {
    //   this.isCopied = true;
    //   return navigator.clipboard.writeText(this.qrUrl);
    // },
  },
};
</script>

<style lang="scss" scoped>
.members-list-container {
  overflow: hidden;
  flex: 1;
}
.toggle-filters-button {
  outline: none;
  box-shadow: none;
  min-width: 25px;
  margin-left: 10px;
}
</style>
