var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 p-0 overflow-hidden"},[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.members,"responsive":"","small":_vm.isSmall,"fields":_vm.fields,"busy":_vm.busy,"primary-key":"id","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{staticClass:"d-flex align-items-center pl-1 ml-25 mt-25 "},[_c('p',{staticClass:"font-weight-extrabold mb-0 text-capitalize truncated-text mt-75"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [(item.type)?_c('p',{staticClass:"mb-0 pl-25 ml-1 text-capitalize truncated-text mt-75"},[_vm._v(" "+_vm._s(item.type)+" ")]):_c('p',{staticClass:"text-muted mb-0 pl-25 ml-1 mt-75"},[_vm._v("---")])]}},{key:"cell(subtype)",fn:function(ref){
var item = ref.item;
return [(item.subtype)?_c('p',{staticClass:"mb-0 pl-25 ml-1 text-capitalize truncated-text mt-75"},[_vm._v(" "+_vm._s(item.subtype)+" ")]):_c('p',{staticClass:"text-muted mb-0 pl-25 ml-1 mt-75"},[_vm._v("---")])]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [(item.createdAt)?_c('p',{staticClass:"mb-0 pl-25 ml-1 text-capitalize truncated-text mt-75"},[_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]):_c('span',{staticClass:"text-muted mb-0 pl-25 ml-1 mt-75"},[_vm._v("---")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('feather-icon',{staticClass:"pointer text-primary ml-3 mt-1",attrs:{"icon":"Trash2Icon"},on:{"click":function($event){return _vm.removeItem(item)}}})]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center p-5"},[_c('b-spinner',{staticClass:"d-block m-auto m-1",attrs:{"size":"2rem","type":"grow","variant":"primary","label":"Loading..."}})],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"horizontal-placeholder d-block"},[_c('div',[_c('img',{staticClass:"d-block",attrs:{"src":_vm.membersPlaceholder}})]),_c('div',[_c('p',{staticClass:"text-primary text-center"},[_vm._v(" "+_vm._s(_vm.$t('available.message', { itemName: _vm.$t('backoffice.templates.title') }))+" ")])])])]},proxy:true}])}),_c('unshare-modal',{attrs:{"model-type":"template"},on:{"remove-item":_vm.handleRemoveItem},model:{value:(_vm.isUnshareModalVisible),callback:function ($$v) {_vm.isUnshareModalVisible=$$v},expression:"isUnshareModalVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }