<template>
  <div>
    <div class="d-flex justify-content-between">

      <!-- Container title -->
      <h4 v-if="isViewAllOrganizations" class="mr-4" style="padding-top: 7px;">
        {{ $t('organizations.title') }}
      </h4>

    <!-- TODO: Change old search input -->
      <!-- Search bar -->
      <b-form-input
        v-if="searchable"
        v-model.trim="searchString"
        class="mb-1"
        type="text"
        :placeholder="searchPlaceholder"
        @input="handleSearchChange"
      />

      <!-- Filter, create and export -->
      <slot name="search-actions" :handleSearchChange="handleSearchChange" />
    </div>
    <slot name="filters" :handleSearchChange="handleSearchChange" />

    <div class="base-pagination-container__page">
      <slot :items="items" :loading="loading" />
    </div>
    <!-- Pagination -->
    <div v-if="!hidePagination">
      <b-row class="mx-1" :class="isMobile?'mb-1 mt-0':'my-2'">
        <!-- Pagination text -->
        <b-col
          v-if="!noShowOrganizations || ( noShowOrganizations && (isSearching || filter))"
          cols="12"
          :xl="total > perPage ? 4 : 12"
          class="d-flex align-items-center justify-content-center justify-content-xl-start p-0"
        >
          <span v-if="total && !loading && showTotal" class="text-muted">
            {{ $t('displaying.message', { itemsFrom: itemsFrom, itemsTo: itemsTo, total }) }}
          </span>
        </b-col>
        <!-- Pagination buttons -->
        <b-col
          v-if="(total > perPage && !noShowOrganizations) || (total > perPage && noShowOrganizations && (searchString != '' || filter))"
          cols="12"
          xl="4"
          class="d-flex align-items-center justify-content-center justify-content-xl-start pl-0 pl-md-1 pr-0 pt-xl-0"
          :class="isMobile?'pt-0':'pt-1'"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0 mx-auto"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BPagination, BRow, BCol, BFormInput,
} from 'bootstrap-vue';

export default {
  name: 'BasePaginationContainer',
  components: {
    BPagination,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    noShowOrganizations: Boolean,
    items: {
      type: [Array, Object],
      required: true,
    },
    total: {
      type: Number,
      required: false,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 4,
    },
    placeholder: {
      type: String,
      default: null,
    },
    searchPlaceholder: {
      type: String,
      default: null,
    },
    loadFirstPage: {
      type: Boolean,
      default: true,
    },
    searchable: Boolean,
    hidePagination: Boolean,
    loading: Boolean,
    filter: {
      type: Boolean,
      default: false,
    },
    isSearching: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      searchString: '',
    };
  },
  computed: {
    itemsFrom() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    itemsTo() {
      if (this.perPage > this.total) return this.total;

      const itemsTo = this.currentPage * this.perPage;
      return itemsTo > this.total ? this.total : itemsTo;
    },
    computedImage() {
      return this.placeholder;
    },
    isMobile() {
      return window.innerWidth < 700;
    },
    isViewAllOrganizations() {
      return this.$route.name === 'organizations' || this.$route.name === 'backoffice-organizations';
    }
  },
  watch: {
    currentPage(currentPage) {
      this.requestPageLoad(currentPage);
    },
    searchString() {
      this.requestPageLoad(1);
    },
  },
  created() {
    if (this.loadFirstPage) {
      this.getInitialData();
    }
  },
  methods: {
    getInitialData() {
      this.requestPageLoad(this.currentPage);
    },
    requestPageLoad(page) {
      this.$emit('load-page', {
        page, 
        perPage: this.perPage, 
        search: this.searchString, 
        forceAPICall: true,
      });
    },
    handleSearchChange() {
      if (this.currentPage === 1) {
        this.requestPageLoad(1);
      } else {
        this.currentPage = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-pagination-container__page {
  overflow: hidden;
  flex: 1;
}
</style>
